// import react from 'React'
import Typography from '@material-ui/core/Typography'

const PageNotFound = () => {
  return (
    <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
      <div
        style={{ paddingBottom: '20%' }}
        className="d-flex flex-column align-items-start"
      >
        <Typography variant="h2" color="primary">
          404
        </Typography>
        <Typography variant="h4" className="pb-2">
          Page not found
        </Typography>
        <Typography variant="body1">
          You should check the form link that you were given or contact your
          examiner
        </Typography>
      </div>
    </div>
  )
}
export default PageNotFound
