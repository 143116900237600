import axios from 'axios'
import { nanoid } from 'nanoid'

export const logFormStart = async ({
  formId,
  respondent,
  storeSubmissionId,
}: {
  formId: string
  respondent?: string
  storeSubmissionId: (submissionId: string) => void
}): Promise<void> => {
  return new Promise((resolve, reject) => {
    // const usr = firebase.auth().currentUser

    // if (!usr) {
    //   throw new Error('Not signed in')
    // }

    // create submission id, it will be found both in form response and firestore
    const submissionId = nanoid().slice(-8)

    console.log('\n--- starting form ---')
    console.log('form id', formId)
    console.log('submission id', submissionId)
    console.log('\n')

    storeSubmissionId(submissionId)

    // dispatch({
    //   type: 'SET_SUBMISSION_ID',
    //   payload: { submissionId: submission },
    // })

    axios
      .post(
        'https://us-central1-form-timer-34cef.cloudfunctions.net/logFormStart',
        {
          formId,
          submission: submissionId,
          // respondent: usr.displayName || usr.email,
          respondent,
        }
      )
      .then((res) => {
        console.log(res)
        console.log(res.data)
        resolve()
      })
      .catch(() => {
        reject('Failed to register form start in firestore')
      })
  })
}
