import React from 'react'
import ReactDOM from 'react-dom'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import 'bootstrap/dist/css/bootstrap.css'

import './style/index.scss'
import { theme } from './style/muiTheme'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AppStateProvider } from './contexts/app/appContext'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppStateProvider>
        <App />
      </AppStateProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
