import React, { useState, useEffect, useCallback } from 'react'

import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import Alarm from '@material-ui/icons/Alarm'
import Done from '@material-ui/icons/Done'
import Close from '@material-ui/icons/Close'

import { palette } from '../style/muiTheme'

const Alarms = ({
  alarms,
  setAlarms,
  remainingSec,
}: {
  alarms: AlarmInterface[]
  setAlarms: React.Dispatch<React.SetStateAction<AlarmInterface[]>>
  remainingSec: number
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleAlarm = useCallback(
    (a: AlarmInterface) => {
      setAlarms((alarms) =>
        alarms
          .filter((el) => el.value !== a.value)
          .concat([{ ...a, isOn: !a.isOn }])
      )
    },
    [setAlarms]
  )

  const activeAlarm = alarms.find((a) => a.isOn && a.value === remainingSec)

  // apply visual and audio alerts
  useEffect(() => {
    if (remainingSec === 0) {
      new Audio('./notification.ogg').play().then(() => {
        alert('Time is up')
      })
    }
    if (activeAlarm) {
      new Audio('./notification.ogg').play().then(() => {
        alert(`${activeAlarm.label} remaining`)
      })
      toggleAlarm(activeAlarm)
    }
  }, [activeAlarm, remainingSec, toggleAlarm])

  return (
    <div className="pl-2">
      <IconButton
        onClick={handleClick}
        size="small"
        disabled={alarms.filter((a) => a.value < remainingSec).length === 0}
      >
        <Alarm />
      </IconButton>

      <Menu
        id="simple-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div className="d-flex flex-column align-items-center border-bottom pb-2">
          Alarms
          <Typography
            variant="caption"
            className="d-flex justify-content-center pb-2"
          >
            Tab must be focused
          </Typography>
        </div>

        {alarms
          .filter((a) => a.value < remainingSec)
          .sort((a, b) => a.value - b.value)
          .map((a) => (
            <AlarmItem
              key={a.value}
              label={a.label}
              value={a.value}
              isOn={a.isOn}
              toggle={() => toggleAlarm(a)}
            />
          ))}
      </Menu>
    </div>
  )
}
export default Alarms

export const alarmsInitial = [
  { label: '2 min', value: 120 },
  { label: '5 min', value: 300 },
  { label: '15 min', value: 900 },
  { label: '30 min', value: 1800 },
  { label: '1 hour', value: 3600 },
  { label: '2 hours', value: 7200 },
].map((a) => ({
  ...a,
  isOn: false,
}))

const AlarmItem = ({
  label,
  value,
  isOn,
  toggle,
}: {
  label: string
  value: number
  isOn: boolean
  toggle: () => void
}) => {
  return (
    <MenuItem onClick={toggle}>
      <div
        style={{
          // color: isOn ? 'white' : 'black',
          minWidth: '120px',
        }}
        className="d-flex justify-content-between align-items-center"
      >
        <div>{label} left</div>
        {isOn ? (
          <Done style={{ color: palette.success }} />
        ) : (
          <Close style={{ color: palette.grey }} />
        )}
      </div>
    </MenuItem>
  )
}

export interface AlarmInterface {
  label: string
  value: number
  isOn: boolean
}
