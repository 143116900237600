import React from "react";
import moment from "moment";
import "moment/min/locales";

import Typography from "@material-ui/core/Typography";

import { isOutOfBounds } from "../utils/time";
import { useApp } from "../contexts/app/appContext";
import { palette } from "../style/muiTheme";
import { Button } from "@material-ui/core";

moment.locale(
  (window.navigator as any).userLanguage || window.navigator.language
);

const OutOfBounds = () => {
  const {
    state: { settings },
  } = useApp();

  if (!settings) {
    throw new Error("no settings found");
  }

  return (
    <div className="d-flex flex-column flex-grow-1 container">
      <div style={formBoxStyle}>
        <Typography className="pb-3" variant="h4">
          Form Closed
        </Typography>

        {isOutOfBounds({
          startDate: settings.startDate,
          endDate: settings.endDate,
        }) === "early" ? (
          settings.startDate?.date ? (
            <div>
              <Typography variant="body1">
                This form will start accepting reponses on
              </Typography>
              <Typography variant="h5" className="py-2">
                {moment(settings.startDate.date).format("LLLL")}
              </Typography>
              <Typography variant="h5">
                ({moment(settings.startDate.date).fromNow()})
              </Typography>
            </div>
          ) : (
            <Typography variant="body1">This form is closed</Typography>
          )
        ) : (
          <Typography variant="body1">
            This form is now closed and does not accept any more answers
          </Typography>
        )}
      </div>

      

      <div className="d-flex justify-content-center">
        <Button
          variant="contained"
          style={{
            borderRadius: 20,
            backgroundColor: "#e1a83f",
            padding: "18px 36px",
            fontSize: "18px",
            fontWeight: "bolder",
            color: "white",
          }}
          onClick={() => {
            window.open(
              "https://brilliant.sjv.io/c/3039649/1055464/12858?subId1=page-form-closed&partnerpropertyid=2900139"
            );
          }}
        >
          Learn more
        </Button>
      </div>
    </div>
  );
};
export default OutOfBounds;

const formBoxStyle = {
  width: "100%",
  borderRadius: "8px",
  border: `1px solid #dadce0`,
  borderTop: `10px solid ${palette.primary}`,
  backgroundColor: "white",
  marginTop: "12px",
  // marginBottom: '12px',
  padding: "24px",
} as React.CSSProperties;
