import React from 'react'
import { Link } from 'react-router-dom'

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { Variant } from '@material-ui/core/styles/createTypography'

const screens = ['screen1', 'screen2', 'screen3', 'screen4']

const Home = () => {
  return (
    <div className="container d-flex flex-column align-items-center">
      <Typography variant="h2" className="pt-4">
        Timer for Google Forms
      </Typography>

      <div style={{ borderTop: '1px solid grey' }} className="pt-4 mt-4">
        <div style={{ maxWidth: '440px' }}>
          <Carousel showArrows={true}>
            {screens.map((screen) => (
              <Paper>
                <img
                  src={`/screenshots/${screen}.jpg`}
                  className="rounded d-flex"
                  alt="screen1"
                />
              </Paper>
            ))}
          </Carousel>
        </div>
        {/* <Card>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="140"
            image="/screenshots/screen1.jpg"
            title="Contemplative Reptile"
          />
        </Card> */}
      </div>

      <div>
        <Button
          className="mb-4"
          variant="contained"
          color="primary"
          onClick={() => {
            const url =
              'https://workspace.google.com/marketplace/app/timer_for_google_forms/341515549984'
            window.open(url, '_blank')
          }}
        >
          Install the add-on
        </Button>
      </div>

      <div
        style={{ borderTop: '1px solid grey' }}
        className="d-flex justify-content-around py-3"
      >
        <div className="p-4">
          <Link to="/privacy" target="_blank">
            Privacy
          </Link>
        </div>
        <div className="p-4">
          <Link to="/terms-of-service" target="_blank">
            Terms of service
          </Link>
        </div>
        <div className="p-4">
          <Link to="/help" target="_blank">
            Support
          </Link>
        </div>
      </div>

      <div
        className=" py-3 d-flex flex-column align-items-start"
        style={{ borderTop: '1px solid grey' }}
      >
        <Title>Features</Title>
        <ul>
          <El>Add a secure timer to your google forms</El>
          <El>Set a starting and/or ending date for the form</El>
          <El>Write custom instructions</El>
          <El>See submission start, end times and duration in a spreadsheet</El>
          <El>Hide the timer for gathering response time metrics</El>
        </ul>

        <Title>Support</Title>
        <Typography>Need a helping hand ?</Typography>
        <Typography>
          contact us by{' '}
          <a
            href="mailto:support@form-timer.com?subject=FormTimer%20(support)"
            rel="noreferrer"
            target="_blank"
          >
            mail
          </a>{' '}
          or visit our{' '}
          <a
            href="https://form-timer.com/help"
            target="_blank"
            rel="noreferrer"
          >
            support page
          </a>{' '}
        </Typography>

        <Title>Quick Tutorial</Title>
        <ol>
          <El>Open Google Forms</El>
          <El>Open Timer for Google Forms from the add-on menu</El>
          <El>Configure the test duration and instructions</El>
          <El>Share the link</El>
          <El>See the response times in the linked google sheet</El>
        </ol>

        <Title>Privacy</Title>
        <Typography>
          Here's all the data we use in order to make Timer for Google Forms
          work
        </Typography>
        <ul>
          <El>Your e-mail</El>
          <El>Read and write access to your current form</El>
          <El>Read and write access to your spreadsheets</El>
          <Typography variant="caption">
            (We only edit the spreadsheet linked to the google form)
          </Typography>
          <El>Your Timer for Google Forms settings</El>
          <Typography>
            Timer for Google Forms is <strong>GDPR compliant</strong> and{' '}
            <strong>does not share your data</strong> with any third-party
          </Typography>
        </ul>

        <Title>Associated searches</Title>
        <Typography>
          form timer, google forms timer, timer google forms, free timer for
          google forms, form limiter, exam with google forms, google forms
          assessments, google forms limiter, online teaching google forms,
          online form tests
        </Typography>
      </div>
    </div>
  )
}
export default Home

const Title = ({ children }: { children: React.ReactNode }) => (
  <div className="pb-2 pt-5">
    <Typography variant="h5">{children}</Typography>
  </div>
)

const El = ({
  children,
  variant,
}: {
  children: React.ReactNode
  variant?: Variant
}) => (
  <li>
    <Typography variant={variant}>{children}</Typography>
  </li>
)
