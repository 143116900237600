import { BoundaryDate } from '../contexts/app/appContextTypes'

export const isOutOfBounds = ({
  startDate,
  endDate,
}: {
  startDate: BoundaryDate | undefined
  endDate: BoundaryDate | undefined
}): 'early' | 'no' | 'late' => {
  const now = Date.now()

  if (
    startDate &&
    startDate.isOn &&
    !!startDate.date &&
    now < new Date(startDate.date).getTime()
  ) {
    return 'early'
  } else if (
    endDate &&
    endDate.isOn &&
    !!endDate.date &&
    now > new Date(endDate.date).getTime()
  ) {
    return 'late'
  } else {
    return 'no'
  }
}
