import firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyBF-G-HULL8f_BYfXoZR4CcKKjWLO40qS4',
  authDomain: 'form-timer-34cef.firebaseapp.com',
  projectId: 'form-timer-34cef',
  storageBucket: 'form-timer-34cef.appspot.com',
  messagingSenderId: '403110323663',
  appId: '1:403110323663:web:2ea88fd88d72234d211eba',
  measurementId: 'G-XP8K28YKFD',
}
firebase.initializeApp(firebaseConfig)

export default firebase
