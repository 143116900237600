import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import humanizeDuration from "humanize-duration"

// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import Timer from "@material-ui/icons/Timer"

import { palette } from "../style/muiTheme"

import { useApp } from "../contexts/app/appContext"
import { Settings } from "../contexts/app/appContextTypes"

import { logFormStart } from "../utils/logFormStart"

const WelcomePage = ({ settings: { formId, formInstructions, timeAllowed, formTitle } }: { settings: Settings }) => {
    const history = useHistory()
    const { dispatch } = useApp()

    const [respondent, setRespondent] = useState("")

    // const uiConfig = {
    //   signInFlow: 'popup',
    //   signInOptions: [
    //     firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //     // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //   ],
    //   callbacks: {
    //     signInSuccessWithAuthResult: (authResult: any) => {
    //       console.log('authResult:', authResult)
    //       startForm()
    //       history.push('/app')
    //       return true
    //     },
    //   },
    // }

    // const url = `https://docs.google.com/forms/d/e/\
    //             ${form.id}/viewform?usp=pp_url&\
    //             ${submissionFormItem.htmlEntry}=submissionId`;

    const storeSubmissionId = (submissionId: string) => {
        dispatch({
            type: "SET_SUBMISSION_ID",
            payload: { submissionId }
        })
    }

    // TODO: add container here
    return (
        <div
            className="d-flex flex-column flex-grow-1 justify-content-between align-items-center container"
            // style={{
            //   marginLeft: '20%',
            //   marginRight: '20%',
            // }}
            style={{ maxHeight: "100%" }}
        >
            <div className="w-100">
                {/* <TimingBanner /> */}
                <div className="p-4" style={formBoxStyle}>
                    <div className="pb-4 d-flex justify-content-between align-items-start">
                        <Typography variant="h4">{formTitle ? formTitle : "Timed Form"}</Typography>

                        <div className="d-flex align-items-center">
                            {timeAllowed && (
                                <div className="d-flex flex-column align-items-end  pr-2">
                                    <div className="d-flex">
                                        <Typography variant="body1">time allowed</Typography>
                                    </div>
                                    <Typography variant="h6">{humanizeDuration(timeAllowed * 1000)}</Typography>
                                </div>
                            )}
                            <Timer style={{ fontSize: "3.4em" }} color="primary" />
                        </div>
                    </div>

                    <Typography variant="body1">{formInstructions && <>{formInstructions}</>}</Typography>

                    {/* <Typography className="pb-4" variant="h5">
          Enter your full name
        </Typography> */}
                </div>

                <div className="p-4" style={formBoxStyle}>
                    <TextField
                        className="w-100"
                        label="your full name"
                        value={respondent}
                        onChange={(e) => setRespondent(e.target.value)}
                    />
                </div>

                <Button
                    disabled={respondent === ""}
                    className="w-100"
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "initial", marginTop: "12px" }}
                    onClick={() => {
                        logFormStart({ formId, respondent, storeSubmissionId }).then(() => {
                            history.push("/app")
                        })
                        dispatch({ type: "SET_START", payload: new Date() })
                    }}
                >
                    <Typography variant="body1">Start</Typography>
                </Button>

                <div className="w-100 pt-3">
                    <div>
                        <Typography variant="caption">
                            This is a timed form, the time it takes you to complete it will be recorded. Make sure you
                            submit on time !
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="caption">
                            If you submit late, your response will still be saved.
                        </Typography>
                    </div>
                </div>

                {/* <button onClick={() => startForm()}>startForm</button>
      <button onClick={() => history.push('/app')}>go to App</button> */}
                {/* <div id="firebaseui-auth-container" /> */}
                {/* <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} /> */}
            </div>

            {/* <div
        className="d-flex justify-content-center my-4"
        style={{ minHeight: 0 }}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/form-timer-34cef.appspot.com/o/brilliant_banner_edited.png?alt=media"
          className="img-fluid"
          onClick={() => {
            window.open(
              "https://brilliant.sjv.io/c/3039649/1055464/12858?subId1=page-start&partnerpropertyid=2900139"
            );
          }}
          style={{ cursor: "pointer" }}
        />
      </div> */}

            {/* <div>
        <img style={{maxHeight: "40vh"}} src="https://firebasestorage.googleapis.com/v0/b/form-timer-34cef.appspot.com/o/2560x1640_-_Excel_in_math_and_science.png?alt=media" className="img-fluid"/>
      </div> */}

            <div className="mb-3 d-flex flex-column justify-content-center align-items-center">
                <Typography variant="caption">
                    Made with{" "}
                    <a
                        href="https://workspace.google.com/marketplace/app/timer_for_google_forms/341515549984"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Timer for Google Forms
                    </a>
                </Typography>
                <Typography variant="caption" className="pt-1">
                    <a href="https://soundcloud.com/gaspardfeuvray/sets/dj-sets" target="_blank" rel="noreferrer">
                        Some music
                    </a>{" "}
                    to listen to while you work
                </Typography>

                <Typography variant="caption" className="pt-1">
                    My friend's{" "}
                    <a href="https://www.youtube.com/watch?v=n0siL5awlkk" target="_blank" rel="noreferrer">
                        new clip
                    </a>
                </Typography>
            </div>
        </div>
    )
}
export default WelcomePage

const formBoxStyle = {
    width: "100%",
    borderRadius: "8px",
    border: `1px solid #dadce0`,
    borderTop: `10px solid ${palette.primary}`,
    backgroundColor: "white",
    marginTop: "12px",
    // marginBottom: '12px',
    padding: "24px"
} as React.CSSProperties
