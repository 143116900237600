import React from 'react'

const LoadingPage = () => {
  return (
    <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center">
      <div className="loader">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
        </svg>
      </div>
    </div>
  )
}
export default LoadingPage
