import React, { useEffect } from "react";
import TimingBanner from "./TimingBanner";
import { useApp } from "../contexts/app/appContext";
import firebase from "../utils/firebase";

const FormTimer = () => {
  const {
    state: {
      submissionId,
      settings,
      submissionStatus: { start, end },
    },
    dispatch,
  } = useApp();

  // listen to submissions for closing on submission
  useEffect(() => {
    if (settings && submissionId) {
      firebase
        .firestore()
        .collection("forms")
        .doc(settings.formId)
        .collection("submissions")
        .where("submitted", "==", submissionId)
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            console.log("received submission end trigger");
            dispatch({ type: "SET_END", payload: new Date() });
          }
        });
    }
  }, [dispatch, settings, submissionId]);

  if (!settings || !submissionId) {
    return <div />;
  }
  const { prefilledFormId, htmlEntry } = settings;

  const prefilledFormUrl = `https://docs.google.com/forms/d/e/${prefilledFormId}/viewform?usp=pp_url&entry.${htmlEntry}=${submissionId}`;

  return (
    <div
      className="d-flex flex-column flex-grow-1"
      style={{ backgroundColor: "white" }}
    >
      <TimingBanner hasEnded={start && end ? { start, end } : undefined} />

      <div
        className="d-flex flex-column flex-grow-1"
        style={{ position: "relative" }}
      >
        {prefilledFormUrl && (
          <div className="d-flex flex-column flex-grow-1">
            <iframe
              className="w-100 flex-grow-1"
              id="formiframe"
              title="form"
              src={prefilledFormUrl}
              // width="100%"
              frameBorder="0"
            >
              Loading…
            </iframe>
          </div>
        )}

        {/* <div
          className="d-flex justify-content-center w-100"
          style={{ minHeight: 0, position: "absolute", bottom: 0 }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/form-timer-34cef.appspot.com/o/banner2.png?alt=media"
            className="img-fluid"
            onClick={() => {
              window.open(
                "https://brilliant.sjv.io/c/3039649/1055464/12858?subId1=page-app&partnerpropertyid=2900139"
              );
            }}
            style={{ cursor: "pointer" }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default FormTimer;
