import React, { createContext, useContext, useReducer } from 'react'

import { AppDispatch, appStateReducer } from './appContextActions'
import { AppState } from './appContextTypes'

import { createBrowserHistory } from 'history'

interface AppContext {
  state: AppState
  dispatch: AppDispatch
}

const initialAppState: AppState = {
  history: createBrowserHistory(),
  submissionStatus: {},
}

export const appContext = createContext<AppContext | null>(null)

// Context provider
export const AppStateProvider = ({
  children,
}: {
  children: React.ReactElement
}): React.ReactElement => {
  const [state, dispatch] = useReducer(appStateReducer, initialAppState)

  return (
    <appContext.Provider value={{ state, dispatch }}>
      {children}
    </appContext.Provider>
  )
}

// Custom hook to access state and dispatch
export function useApp(): AppContext {
  const context = useContext(appContext)
  if (!context) {
    throw new Error('appContext was not initialized')
  }
  return context
}
