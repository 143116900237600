import React from 'react'
import { LinearProgress } from '@material-ui/core'

import { palette } from '../style/muiTheme'

const Timer = ({
  duration,
  sec,
  min,
  hours,
}: {
  duration: number
  sec: number
  min: number
  hours: number
}) => {
  const elapsed = sec + 60 * min + 3600 * hours
  const progress = (elapsed / duration) * 100
  // const done = progress === 0

  return (
    <div className="flex-grow-1 pr-4">
      <div className="d-flex align-items-center">
        {/* {showTime ? ( */}
        <div
          className="d-flex align-items-center"
          style={{
            fontFamily: 'Product Sans',
            fontSize: '1.2em',
            borderRadius: '2em',
            // width: '6em',
          }}
        >
          {!!hours && (
            <>
              <TimerEl
                value={hours}
                width={timerElWidth - 0.5}
                color={palette.primary}
              />
              <span style={{ color: palette.primary }}>h</span>
            </>
          )}
          {(!!hours || !!min) && (
            <>
              <TimerEl
                pad={!!hours}
                value={min}
                width={timerElWidth}
                color={palette.primary}
              />
              <span style={{ color: palette.primary }}>min</span>
            </>
          )}

          {!min && !hours && (
            <>
              <TimerEl
                pad
                value={sec}
                width={timerElWidth}
                color={palette.primary}
              />
              <span style={{ color: palette.primary }}>sec</span>
            </>
          )}
        </div>

        <LinearProgress
          style={{ height: '5px', borderRadius: '6px' }}
          variant="determinate"
          value={progress}
          className="w-100 ml-3"
        />
      </div>
    </div>
  )
}

export default Timer

const TimerEl = ({
  value,
  width,
  color,
  pad,
}: {
  value: number
  width: number
  color: string
  pad?: boolean
}) => (
  <div
    className={center}
    style={{
      ...timerStyle,
      minWidth: `${width}em`,
      color,
    }}
  >
    {pad ? value.toString().padStart(2, '0') : value}
  </div>
)

const center = 'd-flex justify-content-center'

const timerElWidth = 1.6

const timerStyle = {
  // border: '1px solid black',
  borderRadius: '0.2em',
  // backgroundColor: 'bisque',
} as React.CSSProperties
