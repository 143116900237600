import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

export const palette = {
  grey: 'rgba(0, 0, 0, 0.18)',
  darkGrey: '#474854',
  primary: '#673ab7',
  primaryDisabled: '#B88FFF',
  lightPrimary: '#ede7f6',
  blue: '#4285F4',
  red: '#ea4335',
  yellow: '#F4B400',
  green: '#0F9D58',
  success: ' #4caf50',
}

export const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: palette.primary,
      },
      secondary: {
        main: palette.darkGrey,
      },
    },
    typography: {
      fontSize: 14,
      fontFamily: [
        'Google Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
      MuiTooltip: {
        arrow: true,
      },
    },
    overrides: {
      MuiTooltip: {
        arrow: { color: palette.darkGrey },
        tooltip: { backgroundColor: palette.darkGrey, fontSize: '0.8em' },
      },
      MuiTypography: {
        h4: {
          fontWeight: 400,
        },
      },
    },
  })
)
