import React from 'react'

import Typography from '@material-ui/core/Typography'

const Privacy = () => {
  return (
    <div className="container">
      <Typography variant="h5" className="pt-4">
        Timer for Google Forms
      </Typography>
      <Typography variant="h4" className="py-2">
        Privacy Policy
      </Typography>

      <P title>Important Notice</P>

      <P>
        We do not store your file data such as forms or spreadsheets on any of
        our servers. Those stay on Google’s servers. Granting us the access to
        your data does not allow us to access your data offline. We only use
        this access to display them on the app and make actions on your behalf
        for the addon to work. All the actions performed come from you.
      </P>
      <P>
        The only information we store from your Google account is your e-mail.
      </P>
      <P>
        <strong>We do not share your data with any third party.</strong>
      </P>

      <P>
        Timer for Google Forms (TfGF) adheres to the Swiss safe harbor privacy
        principles of Notice, Choice, Onward Transfer, Security, Data Integrity,
        Access and Enforcement. If you have any questions about this Policy,
        please feel free to contact us at support@form-timer.com.
      </P>

      <P title>Information we collect and how we use it</P>

      <P>
        TfGF processes personal information on GCP servers in the United States
        of America, in Switzerland, and in other countries. In some cases, we
        process personal information on a server outside your own country. We
        may process personal information to provide our own services.
      </P>
      <P>
        Timer for Google Forms only processes personal information for the
        purposes of
      </P>
      <P>
        - Providing our products and services to users, including the display of
        customized content
      </P>
      <P>- Ensuring the technical functioning and safety of our system</P>
      <P>- Improving the quality of the service we offer</P>

      <P title>Data Protection</P>
      <P>
        We take appropriate security measures to protect against unauthorized
        access to or unauthorized alteration, disclosure or destruction of data.
        These include internal reviews of our data collection, storage and
        processing practices and security measures, as well as physical security
        measures to guard against unauthorized access to systems where we store
        personal data.
      </P>
    </div>
  )
}
export default Privacy

const P: React.FunctionComponent<{ title?: boolean }> = ({
  children,
  title,
}) => <div className={`py-2 ${!!title && 'font-weight-bold'}`}>{children}</div>
