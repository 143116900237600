import { Dispatch, Reducer } from 'react'
import { produce } from 'immer'

import { AppState, Settings } from './appContextTypes'

export type ActionType<Action, Payload = void> = Payload extends void
  ? { type: Action }
  : { type: Action; payload: Payload }

export type AppActions =
  // _______ APP ACTIONS _______

  // Initialize app
  | ActionType<'INITIALIZE_APP', Settings>
  | ActionType<
      'SET_SUBMISSION_ID',
      {
        submissionId: string
      }
    >
  | ActionType<'SET_START', Date>
  | ActionType<'SET_END', Date>

export type AppDispatch = Dispatch<AppActions>

export const appStateReducer: Reducer<AppState, AppActions> = produce(
  (draft: AppState, action: AppActions) => {
    switch (action.type) {
      case 'INITIALIZE_APP': {
        draft.settings = action.payload
        break
      }

      case 'SET_SUBMISSION_ID': {
        draft.submissionId = action.payload.submissionId
        break
      }

      case 'SET_START': {
        draft.submissionStatus.start = action.payload
        break
      }
      case 'SET_END': {
        draft.submissionStatus.end = action.payload
        break
      }

      default:
        throw new Error('Invalid action dispatched in appContext')
    }
  }
)
