import React from 'react'
import humanizeDuration from 'humanize-duration'

import { palette } from '../style/muiTheme'

const SubmissionReport = ({
  times: { start, end },
}: {
  times: {
    start: Date
    end: Date
  }
}) => {
  return (
    <div
      className="pl-2"
      style={{
        fontFamily: 'Product Sans',
        fontSize: '1.2em',
        color: palette.primary,
      }}
    >
      <span>Time recorded : </span>
      <span
        style={{
          color: palette.primary,
        }}
      >
        {' '}
        {humanizeDuration(end.getTime() - start.getTime(), {
          largest: 2,
          round: true,
        })}
      </span>
    </div>
  )
}
export default SubmissionReport
