import React, { useState } from 'react'
import { useTimer } from 'react-timer-hook'

import Chip from '@material-ui/core/Chip'
import FingerprintIcon from '@material-ui/icons/Fingerprint'

import { useApp } from '../contexts/app/appContext'

import Timer from './Timer'
import SubmissionReport from './SubmissionReport'
import Alarms, { alarmsInitial, AlarmInterface } from './Alarms'

// display submission id above just in case

const TimingBanner = ({
  hasEnded,
}: {
  hasEnded?: { start: Date; end: Date }
}) => {
  const {
    state: { submissionId, settings },
  } = useApp()
  const [alarms, setAlarms] = useState<AlarmInterface[]>(alarmsInitial)

  if (!submissionId) {
    throw new Error('No submission id defined')
  }
  if (!settings || !settings.timeAllowed) {
    throw new Error(
      'timer cannot be used, settings or time allowed does not exist'
    )
  }

  const duration = settings.timeAllowed

  const endTime = new Date()
  endTime.setSeconds(endTime.getSeconds() + duration)
  const expiryTimestamp = endTime.getTime()

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
  })
  const remainingSec = seconds + 60 * (minutes + 60 * hours)

  return (
    <div className="container">
      <div className="border-bottom container d-flex justify-content-between align-items-center px-0 py-1">
        {hasEnded ? (
          <SubmissionReport times={hasEnded} />
        ) : (
          <Timer
            duration={duration}
            sec={seconds}
            min={minutes}
            hours={hours}
          />
        )}
        <div className="d-flex align-items-center">
          <Chip icon={<FingerprintIcon />} label={submissionId} size="small" />
          <Alarms
            alarms={alarms}
            setAlarms={setAlarms}
            remainingSec={remainingSec}
          />
        </div>
      </div>
    </div>
  )
}
export default TimingBanner
